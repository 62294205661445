html,
body {
  padding: 0;
  margin: 0;
  height: 100vh;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

.qr-code,
.qr-code * {
  transition: fill 2s ease;
}

@keyframes double-click {
  80%,
  85% {
    transform: scale(1);
  }
  85%,
  90% {
    transform: scale(1.6);
  }
  90%,
  94% {
    transform: scale(1.1);
  }
  94%,
  96% {
    transform: scale(1.6);
  }
  96%,
  100% {
    transform: scale(1);
  }
}

@keyframes one-click {
  80%,
  85% {
    transform: scale(1);
  }

  85%,
  94% {
    transform: scale(0.8);
  }
  94%,
  96% {
    transform: scale(1.1);
  }
  96%,
  100% {
    transform: scale(1);
  }
}

/**
 * ----------------------------------------
 * animation fade-in
 * ----------------------------------------
 */
@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fade-in-down {
  0% {
    opacity: 0;
    transform: translateY(-140px);
  }
  20% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}
